const OptionCard = ({ name, imgSrc, active, activate, onClick, border }) => {
  const theme = localStorage.getItem("themeMode");
  return (
    <div
      onClick={onClick || activate}
      style={{
        borderRadius: "10px",
        padding: 2,
        height: "40px",

        border:
          active &&
          `2px solid ${
            border ? (theme === "dark" ? "#fff" : "#000") : "#0c57ec"
          }`,
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {imgSrc ? (
        <img style={{ height: "100%" }} src={imgSrc} alt={name} />
      ) : (
        name
      )}
    </div>
  );
};
export default OptionCard;
