import React from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { Route, Routes } from "react-router-dom";

import SoldProducts from "../source/SoldProducts";
import Stats from "../source/Stats";
import Orders from "../source/Orders";
import Depositss from "../source/Deposit";
import CreateNewLabel from "../source/CreateLabel";
import Addresses from "../source/Address/index";
import CsvOrders from "../source/CreateBulklabels";
import Logs from "../source/Logs";
const DashboardRoutes = () => {
  const theme = localStorage.getItem("themeMode");
  return (
    <>
      <Navbar />
      <Sidebar />
      <main id="content" role="main" className="main">
        <Routes>
          <Route path="/stats" element={<Stats />} />
          <Route path="/orders/:id" element={<Orders />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/deposit" element={<Depositss />} />
          <Route path="/sold-products" element={<SoldProducts />} />
          <Route
            path="/create-label"
            element={<CreateNewLabel theme={theme} />}
          />
          <Route path="/addresses" element={<Addresses />} />
          <Route path="/create-bulk-label" element={<CsvOrders />} />
          <Route path="/logs" element={<Logs />} />
          <Route path="/*" element={<Stats />} />
        </Routes>
      </main>
    </>
  );
};

export default DashboardRoutes;
