import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Col,
  Container,
  Form,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRandom } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { config, server } from "../env";
import Loader from "../components/Loader";
import Header from "../components/Header";
import { Autocomplete, LoadScript } from "@react-google-maps/api";
import OptionCard from "../components/OptionCard";
import CustomSelect from "../components/CustomSelect";
const pricingItems = ["ups", "usps", "fedex"];
const CreateNewLabel = ({ theme }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState("");
  const [addresses, setAddresses] = useState([]);
  const [address, setAddress] = useState({});
  const [address1, setAddress1] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [types, setTypes] = useState([]);
  const [typeAddress, setTypeAddress] = useState("");
  const [typeAddressT, setTypeAddressT] = useState("");
  const [stats, setStats] = useState({});
  const [selectedCourier, setSelectedCourier] = useState(pricingItems[0]);
  const [finalPrice, setFinalPrice] = useState(0);
  const [Type_id, setType_id] = useState("");
  const [Weight, setWeight] = useState(0);

  const [length, setLength] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [description, setDescription] = useState("");
  const [selectedCountryTo, setSelectedCountryTo] = useState("");
  const [selectedCountryFrom, setSelectedCountryFrom] = useState("");
  const [activeType, setActiveType] = useState({});
  const inputRefFrom = useRef();
  const inputRefTo = useRef();
  const [isSaturdayShipment, setIsSaturdayShipment] = useState(false);
  const [isSignatureRequired, setIsSignatureRequired] = useState(false);
  const [incomings, setIncomings] = useState({});
  useEffect(() => {
    fetchIncomings();
  }, []);

  const fetchIncomings = async () => {
    await axios
      .get(server + "/v1/users/stats", config)
      .then((res) => {
        setIncomings(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [states, setStates] = useState([
    {
      ID: "AK",
      Name: "Alaska",
      Country: "US",
    },
    {
      ID: "AL",
      Country: "US",
      Name: "Alabama",
    },
    {
      ID: "AR",
      Country: "US",
      Name: "Arkansas",
    },
    {
      ID: "AZ",
      Country: "US",
      Name: "Arizona",
    },
    {
      ID: "CA",
      Country: "US",
      Name: "California",
    },
    {
      ID: "CO",
      Country: "US",
      Name: "Colorado",
    },
    {
      ID: "CT",
      Country: "US",
      Name: "Connecticut",
    },
    {
      ID: "DC",
      Country: "US",
      Name: "District Of Columbia",
    },
    {
      ID: "DE",
      Country: "US",
      Name: "Delaware",
    },
    {
      ID: "FL",
      Country: "US",
      Name: "Florida",
    },
    {
      ID: "GA",
      Country: "US",
      Name: "Georgia",
    },
    {
      ID: "HI",
      Country: "US",
      Name: "Hawaii",
    },
    {
      ID: "IA",
      Country: "US",
      Name: "Iowa",
    },
    {
      ID: "ID",
      Country: "US",
      Name: "Idaho",
    },
    {
      ID: "IL",
      Country: "US",
      Name: "Illinois",
    },
    {
      ID: "IN",
      Country: "US",
      Name: "Indiana",
    },
    {
      ID: "KS",
      Country: "US",
      Name: "Kansas",
    },
    {
      ID: "KY",
      Country: "US",
      Name: "Kentucky",
    },
    {
      ID: "LA",
      Country: "US",
      Name: "Louisiana",
    },
    {
      ID: "MA",
      Country: "US",
      Name: "Massachusetts",
    },
    {
      ID: "MD",
      Country: "US",
      Name: "Maryland",
    },
    {
      ID: "ME",
      Country: "US",
      Name: "Maine",
    },
    {
      ID: "MI",
      Name: "Michigan",
      Country: "US",
    },
    {
      ID: "MN",
      Country: "US",
      Name: "Minnesota",
    },
    {
      ID: "MO",
      Country: "US",
      Name: "Missouri",
    },
    {
      ID: "MS",
      Country: "US",
      Name: "Mississippi",
    },
    {
      ID: "MT",
      Country: "US",
      Name: "Montana",
    },
    {
      ID: "NC",
      Country: "US",
      Name: "North Carolina",
    },
    {
      ID: "ND",
      Country: "US",
      Name: "North Dakota",
    },
    {
      ID: "NE",
      Country: "US",
      Name: "Nebraska",
    },
    {
      ID: "NH",
      Country: "US",
      Name: "New Hampshire",
    },
    {
      ID: "NJ",
      Country: "US",
      Name: "New Jersey",
    },
    {
      ID: "NM",
      Country: "US",
      Name: "New Mexico",
    },
    {
      ID: "NV",
      Country: "US",
      Name: "Nevada",
    },
    {
      ID: "NY",
      Country: "US",
      Name: "New York",
    },
    {
      ID: "OH",
      Name: "Ohio",
      Country: "US",
    },
    {
      ID: "OK",
      Name: "Oklahoma",
      Country: "US",
    },
    {
      ID: "OR",
      Name: "Oregon",
      Country: "US",
    },
    {
      ID: "PA",
      Name: "Pennsylvania",
      Country: "US",
    },
    {
      ID: "PR",
      Country: "US",
      Name: "Puerto Rico",
    },
    {
      ID: "RI",
      Country: "US",
      Name: "Rhode Island",
    },
    {
      ID: "SC",
      Country: "US",
      Name: "South Carolina",
    },
    {
      ID: "SD",
      Country: "US",
      Name: "South Dakota",
    },
    {
      ID: "TN",
      Country: "US",
      Name: "Tennessee",
    },
    {
      ID: "TX",
      Country: "US",
      Name: "Texas",
    },
    {
      ID: "UT",
      Country: "US",
      Name: "Utah",
    },
    {
      ID: "VA",
      Country: "US",
      Name: "Virginia",
    },
    {
      ID: "VT",
      Country: "US",
      Name: "Vermont",
    },
    {
      ID: "WA",
      Country: "US",
      Name: "Washington",
    },
    {
      ID: "WI",
      Country: "US",
      Name: "Wisconsin",
    },
    {
      ID: "WV",
      Country: "US",
      Name: "West Virginia",
    },
    {
      ID: "WY",
      Country: "US",
      Name: "Wyoming",
    },

    // write canada provinces here
    {
      ID: "AB",
      Country: "CA",
      Name: "Alberta",
    },
    {
      ID: "BC",
      Country: "CA",
      Name: "British Columbia",
    },
    {
      ID: "MB",
      Country: "CA",
      Name: "Manitoba",
    },
    {
      ID: "NB",
      Country: "CA",
      Name: "New Brunswick",
    },
    {
      ID: "NL",
      Country: "CA",
      Name: "Newfoundland and Labrador",
    },
    {
      ID: "NS",
      Country: "CA",
      Name: "Nova Scotia",
    },
    {
      ID: "NT",
      Country: "CA",
      Name: "Northwest Territories",
    },
    {
      ID: "NU",
      Country: "CA",
      Name: "Nunavut",
    },
    {
      ID: "ON",
      Country: "CA",
      Name: "Ontario",
    },
    {
      ID: "PE",
      Country: "CA",
      Name: "Prince Edward Island",
    },
    {
      ID: "QC",
      Country: "CA",
      Name: "Quebec",
    },
    {
      ID: "SK",
      Country: "CA",
      Name: "Saskatchewan",
    },
    {
      ID: "YT",
      Country: "CA",
      Name: "Yukon",
    },
  ]);

  const [From, setFrom] = useState({
    FromName: "",
    FromStreet: "",
    FromStreet2: "",
    FromPhone: "",
    FromCity: "",
    FromState: "",
    FromZip: "",
    FromCompany: "",
    FromCountry: "",
  });
  const [To, setTo] = useState({
    ToName: "",
    ToStreet: "",
    ToPhone: "",
    ToStreet2: "",
    ToCity: "",
    ToState: "",
    ToZip: "",
    ToCompany: "",
    ToCountry: "",
  });

  const getAddresses = async () => {
    setLoading(<Loader />);
    await axios
      .get(server + `/v1/address/readAll?limit=${30}`, config)
      .then((res) => {
        setLoading("");
        setAddresses(res.data.addresses);
      })
      .catch((err) => {
        setLoading("");
        console.log(err);
      });
  };
  const readLabelTypes = async () => {
    setLoading(<Loader />);
    await axios
      .get(server + "/v1/labeltype/read", config)
      .then((res) => {
        setLoading("");
        setTypes(res.data.labelTypes);
      })
      .catch((err) => {
        setLoading("");
        console.log(err);
      });
  };

  useEffect(() => {
    getAddresses();
    readLabelTypes();
  }, []);
  useEffect(() => {
    setFrom({
      FromName: address.name,
      FromStreet: address.street,
      FromStreet2: address.street2,
      FromCity: address.city,
      FromState: address.state,
      FromZip: address.zip,
      FromPhone: address.phone,
      FromCompany: address.company,
      FromCountry: address.country,
    });
    setSelectedCountryFrom(address.country);
  }, [address]);
  useEffect(() => {
    setTo({
      ToName: address1.name,
      ToStreet: address1.street,
      ToStreet2: address1.street2,
      ToCity: address1.city,
      ToState: address1.state,
      ToPhone: address1.phone,
      ToZip: address1.zip,
      ToCompany: address1.company,
      ToCountry: address1.country,
    });
    setSelectedCountryTo(address1.country);
  }, [address1]);

  useEffect(() => {
    const getPrice = async () => {
      const data = {
        Type: Type_id,
        Weight,
      };

      await axios
        .post(server + "/v1/order/price", data, config)
        .then((res) => {
          setFinalPrice(res.data.price);
        })
        .catch((err) => {
          console.log(err);
          setFinalPrice(0);
        });
    };

    getPrice();
  }, [Type_id, Weight]);

  // create order
  const createOrder = async (e) => {
    e.preventDefault();
    setLoading(<Loader />);
    const data = {
      price: finalPrice,
      Type: e.target.type.value,
      Weight: Weight,
      ToName: To.ToName,
      ToPhone: To.ToPhone,
      ToStreet: To.ToStreet,
      ToStreet2: To.ToStreet2,
      ToCity: To.ToCity,
      ToState: To.ToState,
      ToZip: To.ToZip,
      ToCompany: To.ToCompany || "",
      ToCountry: "US",
      FromName: From.FromName,
      FromStreet: From.FromStreet,
      FromStreet2: From.FromStreet2,
      FromCity: From.FromCity,
      FromState: From.FromState,
      FromZip: From.FromZip,
      FromPhone: From.FromPhone,
      FromCompany: From.FromCompany || "",
      FromCountry: "US",
      height: height || 0,
      width: width || 0,
      length: length || 0,
      description: description || "",
      isSignatureRequired,
      isSaturdayShipment,
    };

    console.log(data);
    if (parseInt(incomings.balance) <= 0) {
      setLoading("");
      toast.error("Not enough balance");
      return;
    }
    await axios
      .post(server + "/v1/order/create", data, config)
      .then((res) => {
        if (res.data.message === "Order created successfully") {
          setLoading("");
          toast.success(res?.data?.message);

          navigate("/orders");
        }
      })
      .catch((err) => {
        setLoading("");
        console.log(err);
        toast.error(err.response?.data?.message);
      });
  };

  const generateMobileNumberFrom = () => {
    let randomPhoneNumber = Math.floor(1000000000 + Math.random() * 9000000000);
    randomPhoneNumber = randomPhoneNumber.toString().slice(0, 10);
    while (randomPhoneNumber.charAt(0) === "1") {
      randomPhoneNumber = Math.floor(1000000000 + Math.random() * 9000000000)
        .toString()
        .slice(0, 10);
    }

    setFrom({
      ...From,
      FromPhone: randomPhoneNumber,
    });
  };
  const generateMobileNumberTo = () => {
    let randomPhoneNumber = Math.floor(1000000000 + Math.random() * 9000000000);
    randomPhoneNumber = randomPhoneNumber.toString().slice(0, 10);
    while (randomPhoneNumber.charAt(0) === "1") {
      randomPhoneNumber = Math.floor(1000000000 + Math.random() * 9000000000)
        .toString()
        .slice(0, 10);
    }

    setTo({
      ...To,
      ToPhone: randomPhoneNumber,
    });
  };

  const handlePlaceChangedFrom = () => {
    const place = inputRefFrom.current.getPlace();
    if (place) {
      let city = "";
      let state = "";
      let zip = "";

      place?.address_components?.forEach((p) => {
        if (p?.types[0] === "locality") city = p?.short_name || "";
        if (p?.types[0] === "administrative_area_level_1")
          state = p?.short_name || "";
        if (p?.types[0] === "postal_code") zip = p?.short_name || "";
      });

      setFrom({
        ...From,
        FromStreet: place.formatted_address.split(",")[0],
        FromStreet2:
          place.formatted_address.split(",").length > 4
            ? place.formatted_address.split(",")[1]
            : "",
        FromCity: city,
        FromState: state,
        FromZip: zip,
      });
    }
  };

  const handlePlaceChangedTo = () => {
    const place = inputRefTo.current.getPlace();
    if (place) {
      let city = "";
      let state = "";
      let zip = "";

      place?.address_components?.forEach((p) => {
        if (p?.types[0] === "locality") city = p?.short_name || "";
        if (p?.types[0] === "administrative_area_level_1")
          state = p?.short_name || "";
        if (p?.types[0] === "postal_code") zip = p?.short_name || "";
      });

      setTo({
        ...To,
        ToStreet: place.formatted_address.split(",")[0],
        ToStreet2:
          place.formatted_address.split(",").length > 4
            ? place.formatted_address.split(",")[1]
            : "",
        ToCity: city,
        ToState: state,
        ToZip: zip,
      });
    }
  };

  return (
    <div className="content container-fluid">
      <Header title="Create Label" />
      <Row className="mb-2">
        <Col xs={12}>
          <div className="page-title-box">
            <div className="page-title-right"></div>
            <h2 className="page-title text-white">Create a Label</h2>
          </div>
        </Col>
      </Row>
      <LoadScript
        googleMapsApiKey={"AIzaSyAs7hqe3ZUJTjrM7KbdVqkdxB__0eCcKgE"}
        libraries={["places"]}
      >
        <form onSubmit={createOrder}>
          {loading}

          <div className="mb-4">
            <Row>
              <Col xs={12} md={6} lg={6}>
                <Card>
                  <Card.Header>
                    <h4>Senders Details </h4>
                  </Card.Header>
                  <Card.Body>
                    <Form.Group className="form-group mb-3">
                      <Form.Label htmlFor="order-id">
                        Select a saved address
                      </Form.Label>
                      <CustomSelect
                        bgFaded
                        name="preSaved"
                        theme={theme}
                        value={typeAddress}
                        onChange={(selectedOption) => {
                          setTypeAddress(selectedOption.value);
                          setAddress(
                            addresses.find(
                              (a) => a._id === selectedOption.value
                            )
                          );
                        }}
                        placeholder="Select Address"
                        options={addresses.map((type) => ({
                          label: `${type.name} ${type.street} ${type.zip} ${type.state} ${type.city}`,
                          value: type._id,
                        }))}
                      />
                    </Form.Group>
                    <div className="form-group mb-3">
                      <label htmlFor="order-id">Country</label>
                      <select
                        className="form-control"
                        name="FromCountry"
                        placeholder="Country"
                        required
                        value={From.FromCountry}
                        onChange={(e) => {
                          setSelectedCountryFrom(e.target.value);
                          setFrom({ ...From, FromCountry: e.target.value });
                        }}
                      >
                        {/* <option value="" disabled selected>
                          Select Country
                        </option> */}
                        <option value="US" selected>
                          United States
                        </option>
                        {/* <option value="CA">Canada</option> */}
                      </select>
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="order-id">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Enter name"
                        name="FromName"
                        value={From.FromName}
                        onChange={(e) => {
                          setFrom({ ...From, FromName: e.target.value });
                        }}
                        required
                      />
                    </div>

                    <div className="form-group mb-3">
                      <label htmlFor="order-id">Company</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Enter company name (optional)"
                        name="FromCompany"
                        value={From.FromCompany}
                        onChange={(e) => {
                          setFrom({
                            ...From,
                            [e.target.name]: e.target.value,
                          });
                        }}
                      />
                    </div>

                    <Form.Group className="mb-3">
                      <Form.Label className="mb-0">Phone</Form.Label>
                      <InputGroup>
                        <FormControl
                          size="lg"
                          type="number"
                          name="FromPhone"
                          placeholder="Enter your Phone"
                          value={From.FromPhone}
                          onChange={(e) => {
                            setFrom({
                              ...From,
                              [e.target.name]: e.target.value,
                            });
                          }}
                        />

                        <InputGroup.Text
                          onClick={() => generateMobileNumberFrom()}
                        >
                          <FontAwesomeIcon icon={faRandom} />
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                    <div className="form-group mb-3">
                      <Autocomplete
                        onLoad={(ref) => (inputRefFrom.current = ref)}
                        onPlaceChanged={handlePlaceChangedFrom}
                        restrictions={{ country: "us" }}
                      >
                        <>
                          <label htmlFor="order-id">Address 1</label>
                          <input
                            type="text"
                            className="form-control"
                            id="fromAddress"
                            placeholder="Enter Address 1"
                            name="FromStreet"
                            value={From.FromStreet}
                            onChange={(e) => {
                              setFrom({
                                ...From,
                                [e.target.name]: e.target.value,
                              });
                            }}
                            required
                          />
                        </>
                      </Autocomplete>
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="order-id">Street 2 (optional)</label>
                      <input
                        type="text"
                        className="form-control"
                        id="fromAddress2"
                        placeholder="Address line 2"
                        name="FromStreet2"
                        value={From.FromStreet2}
                        onChange={(e) => {
                          setFrom({
                            ...From,
                            [e.target.name]: e.target.value,
                          });
                        }}
                      />
                    </div>

                    <div className="form-group mb-3">
                      <label htmlFor="order-id">ZIP Code</label>
                      <input
                        type="text"
                        className="form-control"
                        id="fromzipCode"
                        placeholder="ZipCode"
                        name="FromZip"
                        required
                        value={From.FromZip}
                        onChange={(e) => {
                          setFrom({
                            ...From,
                            [e.target.name]: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="order-id">City</label>
                      <input
                        type="text"
                        className="form-control"
                        id="fromCity"
                        placeholder="City"
                        name="FromCity"
                        required
                        value={From.FromCity}
                        onChange={(e) => {
                          setFrom({
                            ...From,
                            [e.target.name]: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="order-id">State</label>
                      <select
                        name="FromState"
                        id="FromState"
                        className="form-control"
                        required
                        value={From.FromState}
                        onChange={(e) => {
                          setFrom({
                            ...From,
                            FromState: e.target.value,
                          });
                        }}
                      >
                        <option value="">Select state</option>

                        {selectedCountryFrom === "CA"
                          ? states
                              .filter((state) => state.Country === "CA")
                              .map((state, index) => (
                                <option key={index} value={state.ID}>
                                  {state.Name}
                                </option>
                              ))
                          : states
                              .filter((state) => state.Country === "US")
                              .map((state, index) => (
                                <option key={index} value={state.ID}>
                                  {state.Name}
                                </option>
                              ))}
                      </select>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={6} lg={6}>
                <Card>
                  <Card.Header>
                    <h4>Receiver's Details </h4>
                  </Card.Header>
                  <Card.Body>
                    <Form.Group className="form-group mb-3">
                      <Form.Label htmlFor="order-id">
                        Select a saved address
                      </Form.Label>
                      <CustomSelect
                        bgFaded
                        name="preSaved"
                        theme={theme}
                        value={typeAddressT}
                        onChange={(selectedOption) => {
                          setTypeAddressT(selectedOption.value);
                          setAddress1(
                            addresses.find(
                              (a) => a._id === selectedOption.value
                            )
                          );
                        }}
                        placeholder="Select Address"
                        options={addresses.map((type) => ({
                          label: `${type.name} ${type.street} ${type.zip} ${type.state} ${type.city}`,
                          value: type._id,
                        }))}
                      />
                    </Form.Group>
                    <div className="form-group mb-3">
                      <label htmlFor="order-id">Country</label>
                      <select
                        className="form-control"
                        name="ToCountry"
                        placeholder="Country"
                        required
                        value={To.ToCountry}
                        onChange={(e) => {
                          setSelectedCountryTo(e.target.value);
                          setTo({ ...To, ToCountry: e.target.value });
                        }}
                      >
                        <option value="US" selected>
                          United States
                        </option>
                        {/* <option value="CA">Canada</option> */}
                      </select>
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="order-id">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Enter name"
                        name="ToName"
                        required
                        value={To.ToName}
                        onChange={(e) => {
                          setTo({ ...To, [e.target.name]: e.target.value });
                        }}
                      />
                    </div>

                    <div className="form-group mb-3">
                      <label htmlFor="order-id">Company</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Enter company name (optional)"
                        name="ToCompany"
                        value={To.ToCompany}
                        onChange={(e) => {
                          setTo({ ...To, [e.target.name]: e.target.value });
                        }}
                      />
                    </div>

                    <Form.Group className="mb-3">
                      <Form.Label className="mb-0">Phone</Form.Label>
                      <InputGroup>
                        <FormControl
                          size="lg"
                          type="number"
                          name="ToPhone"
                          placeholder="Enter your Phone"
                          value={To.ToPhone}
                          onChange={(e) => {
                            setTo({ ...To, [e.target.name]: e.target.value });
                          }}
                        />
                        <InputGroup.Text
                          onClick={() => generateMobileNumberTo()}
                        >
                          <FontAwesomeIcon icon={faRandom} />
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                    <div className="form-group mb-3">
                      <Autocomplete
                        onLoad={(ref) => (inputRefTo.current = ref)}
                        onPlaceChanged={handlePlaceChangedTo}
                        restrictions={{ country: "us" }}
                      >
                        <>
                          <label htmlFor="order-id">Address 1</label>
                          <input
                            type="text"
                            className="form-control"
                            id="toAddress"
                            placeholder="Enter Address 1"
                            name="ToStreet"
                            required
                            value={To.ToStreet}
                            onChange={(e) => {
                              setTo({
                                ...To,
                                [e.target.name]: e.target.value,
                              });
                            }}
                          />
                        </>
                      </Autocomplete>
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="order-id">Street 2 (optional)</label>
                      <input
                        type="text"
                        className="form-control"
                        id="ToStreet2"
                        placeholder="Address line 2"
                        name="ToStreet2"
                        value={To.ToStreet2}
                        onChange={(e) => {
                          setTo({ ...To, [e.target.name]: e.target.value });
                        }}
                      />
                    </div>

                    <div className="form-group mb-3">
                      <label htmlFor="order-id">ZIP Code</label>
                      <input
                        type="text"
                        className="form-control"
                        id="ToZip"
                        placeholder="Zip Code"
                        name="ToZip"
                        required
                        value={To.ToZip}
                        onChange={(e) => {
                          setTo({ ...To, [e.target.name]: e.target.value });
                        }}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="order-id">City</label>
                      <input
                        type="text"
                        className="form-control"
                        id="ToCity"
                        placeholder="City"
                        name="ToCity"
                        required
                        value={To.ToCity}
                        onChange={(e) => {
                          setTo({ ...To, [e.target.name]: e.target.value });
                        }}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="order-id">State</label>
                      <select
                        name="ToState"
                        id="ToState"
                        className="form-control"
                        required
                        value={To.ToState}
                        onChange={(e) => {
                          setTo({ ...To, [e.target.name]: e.target.value });
                        }}
                      >
                        <option value="">Select state</option>

                        {selectedCountryTo === "CA"
                          ? states
                              .filter((state) => state.Country === "CA")
                              .map((state, index) => (
                                <option key={index} value={state.ID}>
                                  {state.Name}
                                </option>
                              ))
                          : states
                              .filter((state) => state.Country === "US")
                              .map((state, index) => (
                                <option key={index} value={state.ID}>
                                  {state.Name}
                                </option>
                              ))}
                      </select>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
          <Row>
            <Col xs={12} md={6} lg={6}>
              {" "}
              <Card className="mb-4">
                <Card.Header>
                  <h4>Providers & services </h4>
                </Card.Header>
                <Card.Body>
                  <Row className="">
                    <Col xs={12} md={12} lg={12} className="mb-4">
                      <div className="d-flex text-center align-items-center gap-4">
                        {pricingItems.map((p) => (
                          <OptionCard
                            imgSrc={
                              theme === "dark"
                                ? "/images/" + p + "2.svg"
                                : "/images/" + p + ".svg"
                            }
                            // imgSrc={"/images/" + p + "2.svg"}
                            name={p}
                            active={selectedCourier === p}
                            activate={() => setSelectedCourier(p)}
                          />
                        ))}
                      </div>
                    </Col>
                    <Col xs={12} md={12} lg={12} className="mb-3">
                      <div className="form-group">
                        <label>Type</label>
                        <select
                          name="type"
                          id=""
                          className="form-control"
                          onChange={(e) => {
                            setType_id(e.target.value);

                            setActiveType(
                              types.find((type) => {
                                return type._id === e.target.value;
                              })
                            );
                          }}
                        >
                          <option value="">Select type</option>
                          {types
                            .filter(
                              (type) =>
                                type.uid.includes(selectedCourier) ||
                                type.name.includes(
                                  selectedCourier.toLocaleUpperCase()
                                )
                            )
                            .map((type) => (
                              <option key={type._id} value={type._id}>
                                {type.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </Col>
                    {activeType?.name?.includes("UPS") && (
                      <Col
                        xs={12}
                        md={12}
                        lg={12}
                        className="d-flex justify-content-between mt-3"
                      >
                        <Form.Check // prettier-ignore
                          type="switch"
                          id="custom-switch"
                          label="Saturday Delivery"
                          checked={isSaturdayShipment}
                          onChange={(e) =>
                            setIsSaturdayShipment(!isSaturdayShipment)
                          }
                        />
                        <Form.Check // prettier-ignore
                          type="switch"
                          id="custom-switch"
                          label="Signature Required"
                          checked={isSignatureRequired}
                          onChange={(e) =>
                            setIsSignatureRequired(!isSignatureRequired)
                          }
                        />
                      </Col>
                    )}
                    {/* <Col xs={12} md={2} lg={3}>
                      <div className=" d-flex text-align-center align-center mt-3">
                        <p
                          className="text-muted"
                          style={{
                            fontSize: "1.5rem",
                          }}
                        >
                          Price:&nbsp;
                        </p>
                        <p
                          className="text-primary"
                          style={{
                            fontSize: "1.5rem",
                          }}
                        >
                          ${finalPrice}
                        </p>
                      </div>
                    </Col> */}
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={6} lg={6}>
              <Card className="mb-4">
                <Card.Header>
                  <h4>Package Details </h4>
                </Card.Header>
                <Card.Body>
                  <Row className="g-3 row-cols-2 mb-2">
                    <Col
                      xs={activeType?.name?.includes("UPS") ? 6 : 6}
                      md={activeType?.name?.includes("UPS") ? 3 : 12}
                      lg={activeType?.name?.includes("UPS") ? 3 : 12}
                      className=""
                    >
                      <div className="form-group">
                        <label>Weight</label>
                        <Form.Control
                          size="sm"
                          type="number"
                          name="Weight"
                          className="form-control"
                          placeholder={`Package Weight${
                            activeType?.maxWeight
                              ? " (" +
                                activeType?.maxWeight +
                                (activeType?.uid?.includes("first_class") ||
                                activeType?.uid?.includes("ground_oz")
                                  ? " Oz"
                                  : " Lbs") +
                                " Max)"
                              : ""
                          }*`}
                          required
                          onChange={(e) => {
                            setWeight(e.target.value);
                          }}
                        />
                      </div>
                    </Col>
                    {activeType?.name?.includes("UPS") && (
                      <>
                        <Col xs={6} md={3} lg={3}>
                          <div className="form-group ">
                            <label htmlFor="order-id">Length</label>
                            <Form.Control
                              size="sm"
                              type="number"
                              className="form-control"
                              id="length"
                              placeholder="Enter Length (inch) *"
                              name="length"
                              onChange={(e) => {
                                setLength(e.target.value);
                              }}
                            />
                          </div>
                        </Col>
                        <Col xs={6} md={3} lg={3}>
                          <div className="form-group ">
                            <label htmlFor="order-id">Width</label>
                            <Form.Control
                              size="sm"
                              type="number"
                              className="form-control"
                              id="width"
                              placeholder="Enter Width (inch) *"
                              name="width"
                              onChange={(e) => {
                                setWidth(e.target.value);
                              }}
                            />
                          </div>
                        </Col>
                        <Col xs={6} md={3} lg={3}>
                          <div className="form-group ">
                            <label htmlFor="order-id">Height</label>
                            <Form.Control
                              size="sm"
                              type="number"
                              className="form-control"
                              id="height"
                              placeholder="Enter Height (inch) *"
                              name="height"
                              onChange={(e) => {
                                setHeight(e.target.value);
                              }}
                            />
                          </div>
                        </Col>
                      </>
                    )}
                    <Col xs={12} md={12} lg={12}>
                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label className="fs-10"> Description</Form.Label>
                        <Form.Control
                          as="textarea"
                          aria-label="With textarea"
                          rows={2}
                          type="text"
                          className="form-control "
                          id="description"
                          placeholder="Enter Description"
                          name="description"
                          onChange={(e) => {
                            setDescription(e.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    {/* <Col xs={12} md={6} lg={6} className="d-flex flex-column">
                      <Form.Group className="form-group mb-2">
                        <Form.Label>Reference 1</Form.Label>
                        <Form.Control
                          size="sm"
                          type="text"
                          className="form-control"
                          id="reference1"
                          placeholder="Enter Reference 1"
                          name="reference1"
                          onChange={(e) => {
                            setReference1(e.target.value);
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="form-group ">
                        <Form.Label>Reference 2</Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          id="reference2"
                          size="sm"
                          placeholder="Enter Reference 2"
                          name="reference2"
                          onChange={(e) => {
                            setReference2(e.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col> */}
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* show balance and button to create order */}
          <Row>
            <Col xs={12}>
              {" "}
              <Card className="">
                <Card.Body>
                  <div className="d-flex justify-content-between">
                    <span className=" d-flex  text-center align-items-center">
                      <h1
                        className="text-muted"
                        style={{
                          fontSize: "1.5rem",
                        }}
                      >
                        Price:&nbsp;
                      </h1>
                      <h1
                        className="text-primary"
                        style={{
                          fontSize: "1.5rem",
                        }}
                      >
                        ${finalPrice}
                      </h1>
                    </span>
                    <button
                      className="btn btn-primary btn-xl "
                      type="submit"
                      disabled={isDisabled}
                    >
                      Create Order
                    </button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </form>
      </LoadScript>
    </div>
  );
};

export default CreateNewLabel;
