import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../auth/Login";

const AuthRouter = () => {
  return (
    <Routes>
      <Route path="/*" element={<Login />} />
    </Routes>
  );
};

export default AuthRouter;
