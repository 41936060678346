import { server, config } from "../env";
import axios from "axios";

export default class Requests {
  async updatePassword(password) {
    var error = null;
    var message = "";
    await axios
      .post(server + "/v1/admin/updatePassword", { password }, config)
      .then((rsp) => {
        message = rsp.data.message;
      })
      .catch((err) => {
        if (err.response) {
          error = true;
          message = err.response.data.message;
        }
      });

    console.log(error, message);

    return { error, message };
  }

  async readVendor(data) {
    var doc = {};
    await axios
      .post(server + "/v1/vendor/read", data, config)
      .then((rsp) => {
        doc = rsp.data.payload;
      })
      .catch((err) => {});
    return doc;
  }
  async sendVendorMessage(data) {
    var doc = {};
    await axios
      .post(server + "/v1/vendor/send-message", data, config)
      .then((rsp) => {
        doc = rsp.data.payload;
      })
      .catch((err) => {});
    return doc;
  }

  // support
  async readSupport(data) {
    var doc = {};
    await axios
      .post(server + "/v1/support/read", data, config)
      .then((rsp) => {
        doc = rsp.data.payload;
      })
      .catch((err) => {});
    return doc;
  }

  // close support request
  async closeOrOpenSupport(id) {
    var doc = {};
    await axios
      .patch(server + `/v1/support/${id}`, {}, config)
      .then((rsp) => {
        doc = rsp.data.payload;
      })
      .catch((err) => {});
    return doc;
  }
  // delete support request
  async deleteSupport(id) {
    var doc = {};
    await axios
      .delete(server + `/v1/support/${id}`, config)
      .then((rsp) => {
        doc = {};
      })
      .catch((err) => {});
    return doc;
  }

  // label request
  async readLabel(data) {
    var doc = {};
    await axios
      .post(server + "/v1/label-request/read", data, config)
      .then((rsp) => {
        doc = rsp.data.payload;
      })
      .catch((err) => {});
    return doc;
  }

  // price inquiry
  async readPriceInquiry(data) {
    var doc = {};
    await axios
      .post(server + "/v1/price-inquiry/read", data, config)
      .then((rsp) => {
        doc = rsp.data.payload;
      })
      .catch((err) => {});
    return doc;
  }

  // inventory routes
  async createInventory(data) {
    var doc = {};
    await axios
      .post(server + "/v1/inventory/create", data, config)
      .then((rsp) => {
        doc = rsp.data.payload;
      })
      .catch((err) => {});
    return doc;
  }

  async getAllInventories(data) {
    var doc = {};
    await axios
      .post(server + "/v1/inventory/read", data, config)
      .then((rsp) => {
        doc = rsp.data.payload;
      })
      .catch((err) => {});
    return doc;
  }

  async updateInventory(id, data) {
    var doc = {};
    await axios
      .patch(server + `/v1/inventory/${id}`, data, config)
      .then((rsp) => {
        doc = rsp.data.payload;
      })
      .catch((err) => {});
    return doc;
  }

  async deleteInventory(id) {
    var doc = {};
    await axios
      .delete(server + `/v1/inventory/${id}`, config)
      .then((rsp) => {
        doc = {};
      })
      .catch((err) => {});
    return doc;
  }

  // incoming routes
  async createIncoming(data) {
    var doc = {};
    await axios
      .post(server + "/v1/incoming/create", data, config)
      .then((rsp) => {
        doc = rsp.data.payload;
      })
      .catch((err) => {
        doc = err.response.data;
      });
    return doc;
  }

  async getAllIncomings(data) {
    var doc = {};
    await axios
      .post(server + "/v1/incoming/read", data, config)
      .then((rsp) => {
        doc = rsp.data.payload;
      })
      .catch((err) => {});
    return doc;
  }

  async updateIncoming(id, data) {
    var doc = {};
    await axios
      .patch(server + `/v1/incoming/${id}`, data, config)
      .then((rsp) => {
        doc = rsp.data.payload;
      })
      .catch((err) => {});
    return doc;
  }

  async deleteIncoming(id) {
    var doc = {};
    await axios
      .delete(server + `/v1/incoming/${id}`, config)
      .then((rsp) => {
        doc = {};
      })
      .catch((err) => {});
    return doc;
  }

  // sold product routes
  async createSoldProduct(data) {
    var doc = {};
    await axios
      .post(server + "/v1/sold-product/create", data, config)
      .then((rsp) => {
        doc = rsp.data.payload;
      })
      .catch((err) => {
        doc = err.response.data;
      });
    return doc;
  }

  async getAllSoldProdcuts(data) {
    var doc = {};
    await axios
      .post(server + "/v1/sold-product/read", data, config)
      .then((rsp) => {
        doc = rsp.data.payload;
      })
      .catch((err) => {});
    return doc;
  }

  async updateSoldProduct(id, data) {
    var doc = {};
    await axios
      .patch(server + `/v1/sold-product/${id}`, data, config)
      .then((rsp) => {
        doc = rsp.data.payload;
      })
      .catch((err) => {});
    return doc;
  }

  async deleteSoldProduct(id) {
    var doc = {};
    await axios
      .delete(server + `/v1/sold-product/${id}`, config)
      .then((rsp) => {
        doc = {};
      })
      .catch((err) => {});
    return doc;
  }

  // delete inventory photo
  async deletePhoto(id, data) {
    var doc = {};
    await axios
      .patch(server + `/v1/inventory/photo/delete/${id}`, data, config)
      .then((rsp) => {
        doc = rsp.data.payload;
      })
      .catch((err) => {
        doc = err.response.data;
      });
    return doc;
  }
}
