import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";
import { config, server } from "../env";
import axios from "axios";
import { Badge } from "react-bootstrap";
import Cookies from "js-cookie";

const Logs = () => {
  const [products, setProducts] = useState([]);
  const [loader, setLoader] = useState("");
  const [statsData, setStatsData] = useState([]);
  const [logsData, setLogsData] = useState([]);
  const [userData, setUserData] = useState({});
  const [loaders, setLoaders] = useState(false);
  const [userOrdersStats, setUserOrdersStats] = useState({});
  const [sort, setSort] = useState("asc");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);

  const [pageLog, setPageLog] = useState(1);
  const [limitLog, setLimitLog] = useState(10);
  const [totalPagesLog, setTotalPagesLog] = useState(0);
  const [totalLog, setTotalLog] = useState(0);
  const [url, setUrl] = useState("/v1/log/read/");
  const [order, setOrder] = useState({});
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  useEffect(() => {
    fetchUserLogs();
  }, [pageLog, limitLog]);
  const fetchUserLogs = async (search) => {
    var link = "";
    if (search) {
      link =
        url + "?page=" + pageLog + "&limit=" + limitLog + "&search=" + search;
    } else {
      link = url + "?page=" + pageLog + "&limit=" + limitLog;
    }
    await axios
      .get(server + link, config)
      .then((res) => {
        setLogsData(res.data.logs);
        setTotalPagesLog(res.data.totalPages);
        setTotalLog(res.data.total);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const downloadPdf = async () => {
    await axios
      .get(`${server}/v1/order/download-test`, {
        responseType: "blob",
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `file.zip`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        if (err.response) {
        }
      });
  };
  return (
    <div className="content container-fluid">
      <div className="card">
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center w-100">
            <h5 className="card-title">Transaction Logs</h5>
            <button
              onClick={() => {
                downloadPdf();
              }}
            >
              Donload{" "}
            </button>
          </div>
        </div>
        <div className="card-body px-0 pb-0">
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">DATE AND TIME</th>
                  <th scope="col">TYPE</th>
                  <th scope="col">DESCRIPTION</th>
                  <th scope="col">AMOUNT</th>
                  <th scope="col">BALANCE</th>
                  {/* <th scope="col">Created At</th> */}
                  {/* <th scope="col">Action</th> */}
                </tr>
              </thead>

              <tbody>
                {logsData.length === 0 ? (
                  <tr>
                    <td colSpan="12" className="text-center">
                      <span className="text-center">No Orders</span>
                    </td>
                  </tr>
                ) : (
                  logsData?.map((product, i) => (
                    <tr key={product._id}>
                      <td>{i + 1}</td>
                      <td>{product.createdAt}</td>
                      <td>{product.type}</td>
                      <td>{product.action}</td>

                      <td>
                        <h4>
                          {product.amount > 0 && (
                            <Badge bg="success">
                              +${product?.amount?.toFixed(2)}
                            </Badge>
                          )}
                          {product.amount < 0 && (
                            <Badge bg="danger">
                              -${product?.amount?.toFixed(2)}
                            </Badge>
                          )}
                        </h4>
                      </td>
                      <td>${product?.balance?.toFixed(2)}</td>
                      {/* <td>
                          <div className="d-flex gap-2">
                            <button
                              className="btn btn-sm btn-info mr-2"
                              onClick={() => {
                                setOrder(product);
                                onOpenOrderDetailsModal();
                              }}
                            >
                              <i className="bi-eye"></i>
                            </button>
                          </div>
                        </td> */}
                    </tr>
                  ))
                )}
                {loaders && (
                  <tr>
                    <td colSpan="12" className="text-center">
                      <div className="text-center">
                        <Loader />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-end mt-5">
            <nav>
              <ul className="pagination">
                <li
                  className={pageLog === 1 ? "page-item disabled" : "page-item"}
                >
                  <Link
                    className="page-link"
                    to=""
                    onClick={(e) => {
                      setPageLog(pageLog - 1);
                    }}
                  >
                    Prev
                  </Link>
                </li>

                <li className="page-item active" aria-current="page">
                  <Link className="page-link" to="">
                    {pageLog}
                  </Link>
                </li>

                <li
                  className={
                    pageLog === totalPagesLog
                      ? "page-item disabled"
                      : "page-item"
                  }
                >
                  <Link
                    className="page-link"
                    to=""
                    tabIndex={-1}
                    aria-disabled={pageLog === totalPagesLog ? true : false}
                    onClick={(e) => {
                      setPageLog(pageLog + 1);
                    }}
                  >
                    Next
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logs;
