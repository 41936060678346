import React, { useContext, useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import axios from "axios";
import { states } from "../../utils/Contants";
import Cookies from "js-cookie";
import { config, server } from "../../env";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";

const EditAddress = ({ isOpen, onClose, address, getAddresses }) => {
  const [loading, setLoading] = useState("");
  const [formdata, setFormData] = useState({
    name: address.name || "",
    country: address.country || "",
    phone: address.phone || "",
    company: address.company || "",
    street: address.street || "",
    street2: address.street2 || "",
    zip: address.zip || "",
    city: address.city || "",
    state: address.state || "",
  });

  useEffect(() => {}, [address]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(<Loader />);
    console.log(formdata);
    await axios
      .put(server + "/v1/address/update/" + address._id, formdata, config)
      .then((res) => {
        setLoading("");
        getAddresses();
        toast.success(res?.data?.message);
        onClose();
      })
      .catch((err) => {
        setLoading("");
        toast.error(err.response?.data?.message);
      });
    setTimeout(() => {
      setLoading("");
    }, 3000);
  };
  const [selectedCountryFrom, setSelectedCountryFrom] = useState(
    formdata.country
  );

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      size={"xl"}
      centered
    >
      <Modal.Header className="pb-2 px-4 border-bottom-0" closeButton>
        <Modal.Title id="modal-title">
          <h5 className="text-info"> Edit Address </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 pb-4 pt-0">
        <Form onSubmit={onSubmit}>
          {loading}
          <Row>
            <Col sm={6}>
              <FloatingLabel controlId="floatingSelect" label="Country">
                <Form.Select
                  type="select"
                  label="Country"
                  name="country"
                  value={formdata.country}
                  onChange={(e) => {
                    setSelectedCountryFrom(e.target.value);
                    setFormData({ ...formdata, country: e.target.value });
                  }}
                  containerClass={"mb-3"}
                  key="country"
                >
                  <option value="" selected disabled>
                    {" "}
                    States
                  </option>
                  <option value="US">United States</option>
                  {/* <option value="CA">Canada</option> */}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col sm={6}>
              <FloatingLabel label="Name *" className="mb-3">
                <Form.Control
                  type="text"
                  name="name"
                  value={formdata.name}
                  onChange={(e) =>
                    setFormData({ ...formdata, name: e.target.value })
                  }
                  placeholder="Enter Name"
                  containerClass={"mb-3"}
                  key="name"
                />
              </FloatingLabel>
            </Col>
            <Col sm={6}>
              <FloatingLabel label="Phone *" className="mb-3">
                <Form.Control
                  type="tel"
                  name="phone"
                  placeholder="Enter Phone *"
                  value={formdata.phone}
                  onChange={(e) =>
                    setFormData({ ...formdata, phone: e.target.value })
                  }
                  containerClass={"mb-3"}
                  key="phone"
                />
              </FloatingLabel>
            </Col>
            <Col sm={6}>
              <FloatingLabel label="Company" className="mb-3">
                <Form.Control
                  type="text"
                  name="company"
                  value={formdata.company}
                  onChange={(e) =>
                    setFormData({ ...formdata, company: e.target.value })
                  }
                  placeholder="Enter Company Name"
                  containerClass={"mb-3"}
                  key="company"
                />
              </FloatingLabel>
            </Col>
            <Col sm={6}>
              <FloatingLabel label="Address Line 1 *" className="mb-3">
                <Form.Control
                  type="text"
                  name="street"
                  placeholder="Enter Address Line 1"
                  value={formdata.street}
                  onChange={(e) =>
                    setFormData({ ...formdata, street: e.target.value })
                  }
                  containerClass={"mb-3"}
                  key="street"
                />
              </FloatingLabel>
            </Col>
            <Col sm={6}>
              <FloatingLabel label="Address Line 2" className="mb-3">
                <Form.Control
                  type="text"
                  value={formdata.street2}
                  onChange={(e) =>
                    setFormData({ ...formdata, street2: e.target.value })
                  }
                  name="street2"
                  placeholder="Enter Address Line 2"
                  containerClass={"mb-3"}
                  key="street2"
                />
              </FloatingLabel>
            </Col>
            <Col sm={6}>
              <FloatingLabel label="Zipcode *" className="mb-3">
                <Form.Control
                  type="text"
                  name="zip"
                  value={formdata.zip}
                  onChange={(e) =>
                    setFormData({ ...formdata, zip: e.target.value })
                  }
                  placeholder="Enter Zipcode"
                  containerClass={"mb-3"}
                  key="zip"
                />
              </FloatingLabel>
            </Col>
            <Col sm={6}>
              <FloatingLabel label="City *" className="mb-3">
                <Form.Control
                  type="text"
                  name="city"
                  value={formdata.city}
                  onChange={(e) =>
                    setFormData({ ...formdata, city: e.target.value })
                  }
                  placeholder="Enter city"
                  containerClass={"mb-3"}
                  key="city"
                />
              </FloatingLabel>
            </Col>
            <Col sm={6}>
              <FloatingLabel label="State *">
                <Form.Select
                  type="select"
                  name="state"
                  placeholder="Select State"
                  containerClass={"mb-3"}
                  value={formdata.state}
                  onChange={(e) =>
                    setFormData({ ...formdata, state: e.target.value })
                  }
                  key="state"
                >
                  {selectedCountryFrom === "CA"
                    ? states
                        .filter((state) => state.Country === "CA")
                        .map((state, index) => (
                          <option key={index} value={state.ID}>
                            {state.Name}
                          </option>
                        ))
                    : states
                        .filter((state) => state.Country === "US")
                        .map((state, index) => (
                          <option key={index} value={state.ID}>
                            {state.Name}
                          </option>
                        ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col xs={12} className="d-flex justify-content-between">
              <Button className="btn btn-light" onClick={onClose}>
                Close
              </Button>
              <Button
                variant="success"
                type="submit"
                className="btn btn-success"
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditAddress;
