import React from "react";

const Navbar = () => {
  return (
    <header
      id="header"
      className="navbar navbar-expand-lg navbar-fixed navbar-height navbar-container navbar-bordered bg-white"
    >
      <div className="navbar-nav-wrap">
        {/* Logo */}

        <a className="navbar-brand" href="./index.html" aria-label="Front">
          <img
            className="navbar-brand-logo-mini"
            src="/assets/img/logo/logo-dark.svg"
            alt="Logo"
            data-hs-theme-appearance="dark"
          />
        </a>
        {/* End Logo */}
      </div>
    </header>
  );
};

export default Navbar;
