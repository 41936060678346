import React from "react";
import Select from "react-select";
import "../css/main.css";
const CustomSelect = ({
  label,
  options,
  placeholder,
  wAuto,
  bgFaded,
  className,
  theme,
  ...props
}) => {
  const selectStyle = {
    // input: (styles) => ({
    //   ...styles,
    //   color: "#fff",
    // }),
    // control: (styles, { isFocused, isSelected }) => ({
    //   ...styles,
    //   backgroundColor: isSelected ? "#25282a" : isFocused ? "#25282a" : "#fff",
    //   color: "#fff",
    //   borderColor: "#ffffff29",
    // }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isSelected ? "#25282a" : isFocused ? "#25282a" : "#fff",
      color: isSelected ? "#fff" : isFocused ? "#fff" : "#25282a",
    }),
  };

  const selectedOption = options.find((option) => option.value === props.value);
  return (
    <div className={wAuto ? "w-auto" : "w-100"}>
      {label && <label className="opacity-75 fs-sm mb-1">{label}</label>}
      <Select
        {...props}
        options={options}
        defaultValue={selectedOption}
        placeholder={placeholder}
        value={selectedOption}
        styles={selectStyle}
        getOptionLabel={(option) => option.label}
      />
    </div>
  );
};

export default CustomSelect;
