import React, { useContext, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddAddress from "./addAddress";
import axios from "axios";
import EditAddress from "./editAddress";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { config, server } from "../../env";
import Loader from "../../components/Loader";
import Header from "../../components/Header";
import { Link } from "react-router-dom";

const Addresses = () => {
  const [show, setShow] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState("");
  const [deleteLoading, setDeleteLoading] = useState("");
  const [address, setAddress] = useState({});
  const [createEdit, setCreateEdit] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [edit, setEdit] = useState(false);

  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const onOpenEditModal = () => setShowEdit(true);
  const onCloseEditModal = () => setShowEdit(false);
  const onCloseModal = () => {
    setShow(false);
  };
  const onOpenModal = () => setShow(true);

  const onCloseDeleteModal = () => {
    setShowDelete(false);
  };
  const onOpenDeleteModal = () => setShowDelete(true);

  useEffect(() => {
    getAddresses();
  }, [page]);

  const getAddresses = async () => {
    setLoading(<Loader />);
    await axios
      .get(server + `/v1/address/readAll?page=${page}&limit=${10}`, config)
      .then((res) => {
        setAddresses(res.data.addresses);
        setTotalPages(res.data.totalPages);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(""));
  };

  return (
    <div className="content container-fluid">
      <Header title="Addresses" />
      <Row>
        <Col xs={12}>
          <div className="page-title-box">
            <div className="page-title-right"></div>
            <h3 className="page-title text-white">Addresses</h3>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <Row className="mb-2">
                <Col lg={8}>
                  <Row className="gy-2 gx-2 align-items-center justify-content-xl-start justify-content-between">
                    <Col xs="auto">
                      {/* <input
                        type="text"
                        name="search"
                        placeholder="Search..."
                        width="100%"
                      /> */}
                    </Col>
                  </Row>
                </Col>
                <Col lg={4}>
                  <div className="text-xl-end mt-xl-0 mt-2">
                    <Button
                      variant="info"
                      className="mb-2 me-2"
                      onClick={onOpenModal}
                    >
                      <i className="dripicons-to-do me-1"></i> Add New Address
                    </Button>
                  </div>
                </Col>
              </Row>

              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Country</th>
                      <th>Name</th>
                      <th>Street</th>
                      <th>Street 2</th>
                      <th>City</th>
                      <th>zip</th>
                      <th>State</th>

                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {addresses.map((product, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{product.country}</td>
                        <td>{product.name}</td>
                        <td>{product.street}</td>
                        <td>{product.street2}</td>
                        <td>{product.city}</td>
                        <td>{product.zip}</td>
                        <td>{product.state}</td>
                        <td>
                          <div className="d-flex gap-2 text-center align-center">
                            <OverlayTrigger
                              placement="bottom"
                              overlay={<Tooltip>Edit Address</Tooltip>}
                            >
                              <Button
                                variant="warning"
                                className="btn btn-sm"
                                onClick={() => {
                                  setAddress(product);
                                  onOpenEditModal();
                                }}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={<Tooltip>Delete Address</Tooltip>}
                            >
                              <Button
                                variant="danger"
                                className="btn btn-sm"
                                onClick={() => {
                                  setAddress(product);
                                  onOpenDeleteModal();
                                }}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </Button>
                            </OverlayTrigger>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="d-flex justify-content-end mt-5">
                  <nav>
                    <ul className="pagination">
                      <li
                        className={
                          page === 1 ? "page-item disabled" : "page-item"
                        }
                      >
                        <Link
                          className="page-link"
                          to=""
                          onClick={(e) => {
                            setPage(page - 1);
                          }}
                        >
                          Prev
                        </Link>
                      </li>

                      <li className="page-item active" aria-current="page">
                        <Link className="page-link" to="">
                          {page}
                        </Link>
                      </li>

                      <li
                        className={
                          page === totalPages
                            ? "page-item disabled"
                            : "page-item"
                        }
                      >
                        <Link
                          className="page-link"
                          to=""
                          tabIndex={-1}
                          aria-disabled={page === totalPages ? true : false}
                          onClick={(e) => {
                            setPage(page + 1);
                          }}
                        >
                          Next
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {showEdit ? (
        <EditAddress
          isOpen={showEdit}
          onClose={onCloseEditModal}
          address={address}
          getAddresses={getAddresses}
        />
      ) : null}

      {show ? (
        <AddAddress
          isOpen={show}
          onClose={onCloseModal}
          getAddresses={getAddresses}
        />
      ) : null}
      {showDelete ? (
        <DeleteModal
          isOpen={showDelete}
          onClose={onCloseDeleteModal}
          getAddresses={getAddresses}
          address={address}
        />
      ) : null}
    </div>
  );
};

export default Addresses;

const DeleteModal = ({ isOpen, onClose, getAddresses, address }) => {
  const [loading, setLoading] = useState("");

  const deleteAddress = async () => {
    setLoading(<Loader />);
    await axios
      .delete(server + "/v1/address/delete/" + address._id, config)
      .then((res) => {
        getAddresses();
        toast.success(res?.data?.message);
        onClose();
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      })
      .finally(() => setLoading(""));

    setTimeout(() => {
      setLoading("");
    }, 3000);
  };
  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      size={"sm"}
      centered
    >
      <Modal.Header className="pb-2 px-4 border-bottom-0" closeButton>
        <Modal.Title id="modal-title">
          <h5 className="text-danger">Delete Address</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 pb-4 pt-0">
        {loading}
        <div className="d-flex flex-column">
          <p>Are you sure you want to delete this address?</p>
          <Button onClick={deleteAddress} variant="danger">
            Delete
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
