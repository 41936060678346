import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { config, server } from "../env";
import axios from "axios";
import Loader from "../components/Loader";
const paymentMethods = [
  {
    name: "CashApp",
    val: "cashapp",
    icon: "mdi mdi-cash-plus",
  },
  {
    name: "Stripe",
    val: "stripe",
    icon: "mdi mdi-credit-card-multiple",
  },
];
const Depositss = () => {
  const [loading, setLoading] = useState("");
  const [selectedPayementMethod, setSelectedPayementMethod] = useState(
    paymentMethods[0].val
  );
  const [deposits, setDeposits] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [showCashApp, setShowCashApp] = useState(false);
  const [cryptoCoins, setCryptoCoins] = useState([]);
  const [url, setUrl] = useState("");
  const [amount, setAmount] = useState(0);
  const [invoice, setInvoice] = useState({});
  const [CryptoResponseData, setCryptoResponseData] = useState({});
  const [qr, setQr] = useState("");
  const [depositsLoading, setDepositsLoading] = useState(false);
  const [selectedCoin, setSelectedCoin] = useState({});

  const [showCashAppModal, setShowCashAppModal] = useState(false);
  const onCloseCashAppModalModal = () => {
    setShowCashAppModal(false);
  };
  const onOpenCashAppModalModal = () => setShowCashAppModal(true);

  useEffect(() => {
    getDeposits();
  }, [page]);
  useEffect(() => {
    getCryptoCoin();
  }, []);

  const getDeposits = async () => {
    setDepositsLoading(true);
    await axios
      .get(`${server}/v1/invoice/read?page=${page}&limit=${10}`, config)
      .then((res) => {
        setDeposits(res.data.invoices);
        setTotalPages(res.data.totalPages);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setDepositsLoading(false));
  };
  const getCryptoCoin = async () => {
    await axios
      .get(`${server}/v1/invoice/read-crypto`, config)
      .then((res) => {
        setCryptoCoins(res.data.crypto);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log(selectedCoin);
  const AddBalance = async (e) => {
    e.preventDefault();
    setLoading(<Loader />);

    const data = {
      amount,
      ticker: selectedCoin.ticker,
    };

    await axios
      .post(server + "/v1/invoice/cryptoApi-init", data, config)
      .then((res) => {
        setCryptoResponseData(res.data);
        setLoading("");
        getDeposits();
        toast.success(res.data.message);
        onOpenCashAppModalModal();
      })
      .catch((err) => {
        setLoading("");
        toast.error(err.response?.data?.message);
        console.log(err);
      });
    setTimeout(() => {
      setLoading("");
      e.target.reset();
    }, 3000);
  };
  return (
    <div className="content container-fluid">
      <Header title="Deposit" />
      <Row>
        <Col lg={4}>
          <Card>
            <Card.Body>
              <h4 className="header-title mb-3">Deposit Balance</h4>
              {loading}
              <div className="border p-3 mb-3 rounded">
                {loading}
                <form onSubmit={AddBalance}>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="0.00"
                      className="input-field"
                      type="number"
                      name="amount"
                      onChange={(e) => setAmount(e.target.value)}
                      required
                    />
                    <InputGroup.Text
                      style={{ width: "auto", borderLeft: "none" }}
                      className="input-field"
                    >
                      USD
                    </InputGroup.Text>
                  </InputGroup>
                  <h6 className="mb-3 fz-lg">Choose Payment Method</h6>
                  <div className="d-flex justify-content-around">
                    <Row>
                      {cryptoCoins?.map((coin) => {
                        console.log(server + "/", coin.logo);

                        return (
                          <Col className="col-sm-6">
                            <img
                              style={{
                                height: "50px",
                                width: "50px",
                                borderRadius: "50%",
                                cursor: "pointer",
                              }}
                              src={"https://api.bulkup3.com/" + coin.logo}
                              alt={coin.name}
                              onClick={(e) => setSelectedCoin(coin)}
                            />
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                  <button className="btn btn-success" type="submit">
                    {" "}
                    submit
                  </button>
                </form>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={8}>
          <div className="nk-block">
            <div className="row mt-3">
              <div className="col-md-12">
                <div className="card shadow-sm">
                  <div className="card-inner">
                    <div className="card-filter px-4">
                      <div className="d-md-flex justify-content-between align-items-center">
                        {/* <div className="d-md-flex justify-content-center align-items-center ">
                          <div className="form-group ml-2 mt-3">
                            <select
                              name="sort"
                              id=""
                              className="form-control"
                              value={sort}
                              onChange={(e) => {
                                setSort(e.target.value);
                              }}
                            >
                              <option value="asc">
                                Sort by Date Ascending
                              </option>
                              <option value="des">
                                Sort by Date Descending
                              </option>
                            </select>
                          </div>
                        </div> */}
                        {/* <div className="d-md-flex justify-content-center align-items-center">
             
                          <div className="col mt-3">
                            <div className="form-group mr-3 ">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search"
                                onChange={(e) => {
                                  getDeposits(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>

                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col">ID</th>
                              <th scope="col">Amount</th>
                              <th scope="col">Payment Method</th>
                              <th scope="col">Status</th>
                              <th scope="col">Created At</th>
                              {/* <th scope="col">Action</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {deposits.map((invoice) => (
                              <tr key={invoice._id}>
                                <th scope="row">{invoice._id}</th>
                                <td>{"$" + invoice.amount.toFixed(2)}</td>
                                <td>{invoice.payment_method}</td>
                                <td>
                                  {invoice.status === "pending" ? (
                                    <span className="badge badge-warning">
                                      {invoice.status}
                                    </span>
                                  ) : invoice.status === "paid" ? (
                                    <span className="badge badge-success">
                                      {invoice.status}
                                    </span>
                                  ) : (
                                    <span className="badge badge-danger">
                                      {invoice.status}
                                    </span>
                                  )}
                                </td>
                                <td>
                                  {new Date(invoice.createdAt).toLocaleString()}
                                </td>
                                {/* <td>
                                  <div className="d-flex ">
                                    <Link to={invoice.hosted_url}>
                                      <button className="btn btn-sm btn-info mr-2">
                                        <em className="icon ni ni-eye"></em>
                                      </button>
                                    </Link>
                                  </div>
                                </td> */}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="d-flex justify-content-end mt-5">
                        <nav>
                          <ul className="pagination">
                            <li
                              className={
                                page === 1 ? "page-item disabled" : "page-item"
                              }
                            >
                              <Link
                                className="page-link"
                                to=""
                                onClick={(e) => {
                                  setPage(page - 1);
                                }}
                              >
                                Prev
                              </Link>
                            </li>

                            <li
                              className="page-item active"
                              aria-current="page"
                            >
                              <Link className="page-link" to="">
                                {page}
                              </Link>
                            </li>

                            <li
                              className={
                                page === totalPages
                                  ? "page-item disabled"
                                  : "page-item"
                              }
                            >
                              <Link
                                className="page-link"
                                to=""
                                tabIndex={-1}
                                aria-disabled={
                                  page === totalPages ? true : false
                                }
                                onClick={(e) => {
                                  setPage(page + 1);
                                }}
                              >
                                Next
                              </Link>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Modal
        show={showCashAppModal}
        onHide={onCloseCashAppModalModal}
        backdrop="static"
        keyboard={false}
        size={"md"}
        centered={true}
      >
        <Modal.Header className="pb-2 px-4 border-bottom-0" closeButton>
          <Modal.Title id="modal-title">
            <h3>Finish Crypto Payment </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=" text-center align-items-center  mb-2">
            <h4 className="text-inherit" style={{ whiteSpace: "nowrap" }}>
              Your {CryptoResponseData?.ticker?.toUpperCase()} Deposit Address:
            </h4>
            <input
              type="text"
              className="form-control"
              value={CryptoResponseData.address}
            />
          </div>
          <div className="text-center mt-3 d-flex flex-column text-center align-items-center ">
            <img
              // className="w-60 h-60"
              src={"data:image/png;base64," + CryptoResponseData.qrcode}
              alt=""
              style={{ width: "200px", height: "200px", marginBottom: "10px" }}
            />

            <span className="text-center  ">
              Please send only {CryptoResponseData.name} to this deposit
              address. <br />
              {CryptoResponseData.confirmations} Confirmation(s) required.
            </span>
            <span className="text-center   ">
              Send minimum {CryptoResponseData.min}{" "}
              {CryptoResponseData?.ticker?.toUpperCase()} to this address.
            </span>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Depositss;
