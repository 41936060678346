import { faTruck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Card, Col, Modal, Row } from "react-bootstrap";

const ViewDetails = ({ isOpen, onClose, details }) => {
  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      size={"lg"}
      centered
    >
      <Modal.Header className="pb-2 px-4 border-bottom-0" closeButton>
        <Modal.Title id="modal-title">
          <h3 className="text-info"> Order Details </h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 pb-4 pt-0">
        <Row>
          <Col lg={3}>
            <Card>
              <Card.Body>
                <h4 className="header-title mb-3 text-primary">Sender</h4>
                <h5>{details.FromName}</h5>

                <address className="mb-0 font-14 address-lg">
                  {details.FromStreet}
                  <br />
                  {details?.FromStreet2}
                  <br />
                  {details.FromCity}{" "}
                  <span className="ml-1">{details.FromZip}</span>
                  <br />
                  {details.FromState}{" "}
                  <span className="ml-1">{details.FromCountry}</span>
                </address>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={3}>
            <Card>
              <Card.Body>
                <h4 className="header-title mb-3 text-primary">Receiver</h4>
                <h5>{details.ToName}</h5>

                <address className="mb-0 font-14 address-lg">
                  {details.ToStreet}
                  <br />
                  {details?.ToStreet2}
                  <br />
                  {details.ToCity} <span className="ml-1">{details.ToZip}</span>
                  <br />
                  {details.ToState}{" "}
                  <span className="ml-1">{details.ToCountry}</span>
                </address>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={6}>
            <Card>
              <Card.Body>
                <h4 className="header-title mb-3 text-primary text-center">
                  Provider & Services
                </h4>
                <div className="text-center">
                  <FontAwesomeIcon icon={faTruck} />
                  <h5>
                    <b>{details.labelType?.name}</b>
                  </h5>
                  <p className="mb-1">
                    <b>Order ID :</b>{" "}
                    <span className="ml-1"> {details._id}</span>
                  </p>
                  <p className="mb-0 ">
                    <b>Tracking :</b>{" "}
                    <span className="ml-1 text-primary">
                      {" "}
                      {details.tracking}
                    </span>
                  </p>
                  <p className="mb-0">
                    <b>Price :</b> ${Number(details.price).toFixed(2)}
                  </p>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ViewDetails;
